<template>
    <div
        class="relative w-full pb-4 lg:min-h-[240px] lg:bg-[url('/assets/img/hot-match/bg.png')] lg:py-10"
    >
        <div class="container pr-0 lg:px-4">
            <div class="mb-2 justify-between lg:mb-5 lg:flex">
                <div class="text-sm font-bold lg:text-lg">
                    {{ $t('home.hot_match') }}
                </div>
                <ThaCommonViewMore class="hidden lg:block" :link="`/sports`" />
            </div>

            <div class="relative">
                <button
                    id="prev-hot-match"
                    class="absolute left-[-13px] z-10 hidden h-7 w-7 lg:block"
                    aria-label="Previous match"
                ></button>
                <button
                    id="next-hot-match"
                    class="absolute right-[-13px] top-0 z-10 hidden h-7 w-7 lg:block"
                    aria-label="Next match"
                ></button>
                <!-- loading animation -->
                <div class="lg:!ml-0 lg:!p-0 lg:!py-0 lg:!pl-0">
                    <div
                        v-show="hotMatchesBti.length == 0"
                        class="mx-auto grid items-center gap-4 md:grid-cols-2 lg:grid-cols-4"
                    >
                        <BlocksFootballHotMatchLoader class="loader-image" />
                        <BlocksFootballHotMatchLoader
                            class="loader-image hidden md:block"
                        />
                        <BlocksFootballHotMatchLoader
                            class="loader-image hidden lg:block"
                        />
                        <BlocksFootballHotMatchLoader
                            class="loader-image hidden lg:block"
                        />
                    </div>
                </div>

                <Swiper
                    v-show="hotMatchesBti.length > 0"
                    class="z-0 lg:!ml-0 lg:!p-0 lg:!py-0 lg:!pl-0"
                    auto-height
                    :modules="[SwiperScrollbar, SwiperNavigation]"
                    :slides-per-view="1.1"
                    :space-between="8"
                    :breakpoints="{
                        640: {
                            slidesPerView: 2.3,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 16,
                        },
                    }"
                    :navigation="{
                        nextEl: `#next-hot-match`,
                        prevEl: `#prev-hot-match`,
                    }"
                >
                    <SwiperSlide
                        v-for="(item, index) in hotMatchesBti"
                        :key="index"
                        class="cursor-pointer rounded-lg border border-grey-100 bg-white p-4 font-semibold md:mb-2 lg:shadow-none"
                        @click="handleGetGameUrl(item)"
                    >
                        <div class="flex items-center justify-between pb-2">
                            <div
                                class="w-[60%] truncate text-[10px] font-medium text-grey-600 lg:w-[70%] lg:text-xs"
                            >
                                {{ item.league_name }}
                            </div>
                            <div
                                class="line-clamp-1 text-[10px] font-medium text-grey-600 lg:text-xs"
                            >
                                {{ formatDate(item.text_time) }}
                            </div>
                        </div>
                        <div class="mb-2 grid min-h-8 grid-cols-2">
                            <div class="flex items-center space-x-1">
                                <img
                                    :src="item.teams[0].flag_thumbnail"
                                    :alt="item.teams[0].name"
                                    class="h-[20px] w-[20px] rounded-full border bg-white object-contain"
                                    onerror='this.onerror = null; this.src="/assets/img/hot-match/fc_sample.jpg";'
                                    loading="lazy"
                                />
                                <div class="text-wrap text-left text-xs">
                                    {{ item.teams[0].name }}
                                </div>
                            </div>

                            <div
                                class="flex items-center justify-end space-x-1"
                            >
                                <div class="text-right text-xs">
                                    {{ item.teams[1].name }}
                                </div>
                                <img
                                    :src="item.teams[1].flag_thumbnail"
                                    :alt="item.teams[1].name"
                                    class="h-[20px] w-[20px] rounded-full border bg-white object-contain"
                                    onerror='this.onerror = null; this.src="/assets/img/hot-match/fc_sample.jpg";'
                                    loading="lazy"
                                />
                            </div>
                        </div>

                        <div class="grid grid-cols-2 gap-2">
                            <!-- left up -->
                            <div
                                class="font-semibol flex items-center justify-around rounded-full bg-grey-100 py-1 text-xs"
                            >
                                <p class="text-center text-grey-400">H</p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ hdpHomeTeam(item).rate }}
                                </p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ hdpHomeTeam(item).odds }}
                                </p>
                            </div>
                            <!-- right up -->
                            <div
                                class="font-semibol flex items-center justify-around rounded-full bg-grey-100 py-1 text-xs"
                            >
                                <p class="text-center text-grey-400">O</p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ ouHomeTeam(item).rate }}
                                </p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ ouHomeTeam(item).odds }}
                                </p>
                            </div>
                            <!-- left down -->
                            <div
                                class="font-semibol flex items-center justify-around rounded-full bg-grey-100 py-1 text-xs"
                            >
                                <p class="text-center text-grey-400">A</p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ hdpAwayTeam(item).rate }}
                                </p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ hdpAwayTeam(item).odds }}
                                </p>
                            </div>
                            <!-- right down -->
                            <div
                                class="font-semibol flex items-center justify-around rounded-full bg-grey-100 py-1 text-xs"
                            >
                                <p class="text-center text-grey-400">U</p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ ouAwayTeam(item).rate }}
                                </p>
                                <p
                                    class="text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                                >
                                    {{ ouAwayTeam(item).odds }}
                                </p>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    </div>
</template>
<script setup>
import dayjs from 'dayjs'
import {
    useHotMatchesStore,
    useAlertStore,
    useModalStore,
    useUserStore,
    useGamesStore,
} from '~/stores'
import { storeToRefs } from 'pinia'
const SPORT_LINK = '/btisports'

const alertStore = useAlertStore()

const hotMatchesStoreInstance = useHotMatchesStore()
const useUserStoreInstance = useUserStore()
const { hotMatchesBti } = storeToRefs(hotMatchesStoreInstance)
const { isLoggedIn } = storeToRefs(useUserStoreInstance)
const useModalStoreInstance = useModalStore()
const { showLoginModal } = storeToRefs(useModalStoreInstance)
const { getHotMatchesBti } = hotMatchesStoreInstance
const formatDate = (date) => {
    return dayjs(date).format('HH:mm - DD/MM')
}

const hdpHomeTeam = (item) => {
    return item
        ? {
              odds: item?.hdp?.hTeam?.odds ?? 0,
              rate: item?.hdp?.hTeam?.rate ?? 0,
          }
        : {
              odds: 0,
              rate: 0,
          }
}

const hdpAwayTeam = (item) => {
    return item
        ? {
              odds: item?.hdp?.aTeam?.odds ?? 0,
              rate: item?.hdp?.aTeam?.rate ?? 0,
          }
        : {
              odds: 0,
              rate: 0,
          }
}

const ouHomeTeam = (item) => {
    return item
        ? {
              odds: item?.ou?.hTeam?.odds ?? 0,
              rate: item?.ou?.hTeam?.rate ?? 0,
          }
        : {
              odds: 0,
              rate: 0,
          }
}

const ouAwayTeam = (item) => {
    return item
        ? {
              odds: item?.ou?.aTeam?.odds ?? 0,
              rate: item?.ou?.aTeam?.rate ?? 0,
          }
        : {
              odds: 0,
              rate: 0,
          }
}
const { isMobile } = useDevice()
const gameStoreInstance = useGamesStore()
const { getGameUrl } = gameStoreInstance
const handleGetGameUrl = async (payload) => {
    const match_id = payload.match_id || ''
    if (isLoggedIn.value) {
        try {
            const link = `${SPORT_LINK}?matchId=${match_id}`
            if (isMobile) {
                const toSend = {
                    partnerProvider: 'bti',
                    eventId: match_id,
                }
                const { data } = await getGameUrl(toSend)
                if (data.value.status === 'OK') {
                    const { url } = data.value.data
                    setTimeout(() => {
                        window.open(url, '_blank')
                    })
                }
                return
            } else {
                navigateTo(link)
            }
        } catch (error) {
            alertStore.showMessageError(error)
        }
    } else {
        showLoginModal.value = true
    }
}

onMounted(() => {
    nextTick(async () => await getHotMatchesBti())
})
</script>
<style lang="scss" scoped>
:deep() {
    .swiper {
        padding-right: 10px;
    }
}
.hot-match {
    background: url('/assets/img/hot-match/bg.png');
    background-size: 100% 100%;
}

#prev-hot-match,
#next-hot-match {
    top: 50%;
    background: url('/assets/icons/arrow-right.svg');
}

#prev-hot-match {
    transform: translate(0, -50%) rotate(180deg);
}

#next-hot-match {
    transform: translate(0, -50%);
}

#prev-hot-match.swiper-button-disabled,
#next-hot-match.swiper-button-disabled {
    background: url('/assets/icons/arrow-right-active.svg');
}

#prev-hot-match.swiper-button-disabled {
    transform: translate(0, -50%) rotate(0deg);
    cursor: not-allowed;
}

#next-hot-match.swiper-button-disabled {
    transform: translate(0, -50%) rotate(180deg);
    cursor: not-allowed;
}
.card-hot-match {
    @media (max-width: 1024px) {
        box-shadow: 0px 0px 3px 0px #00000014;
    }
}
</style>
