import { defineStore } from 'pinia'
import useCustomFetch from '~/composables/useCustomFetch'
import { STATUS_OK } from '~/constants/apiStatus'

export const useHotMatchesStore = defineStore('hotMatchesStore', () => {
    const fetcher = useCustomFetch()

    const hotMatches = ref([])
    const getHotMatches = async (queryParams) => {
        try {
            const { data } = await fetcher.getPromotion(
                '/hotmatch',
                queryParams
            )
            if (data.value?.status === STATUS_OK) {
                hotMatches.value = data.value.data
            }
        } catch (error) {
            console.log('gamesStore', error)
        }
    }

    const hotMatchesBti = ref([])
    const getHotMatchesBti = async (queryParams) => {
        try {
            const { data } = await fetcher.getPromotion(
                '/bti/hotmatch',
                queryParams
            )
            if (data.value?.status === STATUS_OK) {
                hotMatchesBti.value = data.value.data
            }
        } catch (error) {
            console.log('gamesStore', error)
        }
    }

    return {
        getHotMatches,
        hotMatches,

        getHotMatchesBti,
        hotMatchesBti,
    }
})
