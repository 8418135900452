<template>
    <div class="my-10 min-h-[175px]">
        <div
            class="mb-5 text-lg font-bold capitalize leading-normal text-black"
        >
            {{ $t('home.highlight_game') }}
        </div>
        <div class="grid gap-5 md:grid-cols-4">
            <ThaCommonCategoryCard
                v-for="(game, idx) in games"
                :key="idx"
                :game="game"
            />
        </div>
    </div>
</template>
<script setup>
const games = [
    {
        link: '/sports',
        thumb: 'sportbooks.png',
        title: 'sportbooks',
    },
    {
        link: '/games/tables?partner=',
        thumb: 'casino.png',
        title: 'live casino',
    },
    {
        link: '/casino/all?partner=',
        thumb: 'casino2.png',
        title: 'casino games',
    },
    {
        link: '/games/lottery?partner=',
        thumb: 'lottery.png',
        title: 'lottery',
    },
]
</script>
<style lang="scss" scoped></style>
