<template>
    <div class="lg:py-10">
        <div
            class="mb-2 text-sm font-bold capitalize leading-tight text-black md:leading-normal lg:mb-2 lg:text-lg"
        >
            {{ $t('common.providers') }}
        </div>
        <div
            class="no-scrollbar flex h-[110px] flex-col flex-wrap gap-2 overflow-x-scroll lg:grid lg:h-full lg:min-h-[247px] lg:grid-cols-9 lg:gap-4 lg:overflow-visible"
        >
            <img
                v-for="(partner, idx) in partners"
                :key="idx"
                :src="`${staticUrl}/assets/img/home/partner/v3/${partner.logo}`"
                class="h-[48px] w-[88px] rounded-xl bg-white lg:h-full lg:w-full"
                :class="{
                    'cursor-pointer hover:scale-105': PARTNER_LINK === '1',
                }"
                :alt="partner.alt"
                loading="lazy"
                @click="handleProviderClick(partner)"
            />
        </div>
    </div>
</template>
<script setup>
import { useModalStore, useAlertStore, useUserStore } from '~/stores'
const useUserStoreInstance = useUserStore()
const useAlertStoreInstance = useAlertStore()
const { isLoggedIn } = storeToRefs(useUserStoreInstance)
const useModalStoreInstance = useModalStore()

const { showLoginModal } = storeToRefs(useModalStoreInstance)

const staticUrl = useRuntimeConfig().public.staticUrl
const partners = [
    {
        link: '/ksports',
        logo: 'logo-0.png',
        alt: 'partner',
        require_auth: false,
        key: 'ksports',
        api_url: '/tp/ksportUrl',
    },
    {
        link: '/btisports',
        logo: 'logo-1.png',
        alt: 'partner',
        require_auth: true,
        key: 'btisports',
        api_url: '/gameUrl?partnerProvider=bti',
    },
    {
        link: '/casino/all?partner=evo',
        logo: 'logo-2.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/casino/all?partner=pragmatic',
        logo: 'logo-3.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/slots?partner=evoplay',
        logo: 'logo-4.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/casino/all?partner=vivo',
        logo: 'logo-5.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/slots?partner=playngo',
        logo: 'logo-6.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/casino/all?partner=mg',
        logo: 'logo-7.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/slots?partner=techplay',
        logo: 'logo-8.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/fishing?partner=jdb',
        logo: 'logo-9.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    // Betradar
    {
        link: '/betradar-sports',
        logo: 'logo-10.png',
        alt: 'partner',
        require_auth: true,
        key: 'betradar',
        api_url: '/gameUrl?partnerProvider=betradar&partnerGameId=vfb',
    },
    // Digmaan
    {
        link: '/games/digmaan',
        logo: 'logo-11.png',
        alt: 'partner',
        require_auth: true,
        key: 'digmaan',
        api_url: '/gameUrl?partnerProvider=cockfight&partnerGameId=lobby',
    },
    {
        link: '/casino/all?partner=dreamgaming',
        logo: 'logo-12.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/casino/all?partner=ezugi',
        logo: 'logo-13.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/fishing?partner=fachai',
        logo: 'logo-14.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/instants?partner=ibc',
        logo: 'logo-15.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    // Inplay
    {
        link: '/im-sports',
        logo: 'logo-16.png',
        alt: 'partner',
        require_auth: true,
        key: 'im-sports',
        api_url: '/gameUrl?partnerProvider=im&partnerGameId=esport',
    },
    {
        link: '/games/fishing?partner=jili',
        logo: 'logo-17.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/casino/all?partner=kingmaker',
        logo: 'logo-18.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/slots?partner=qtech',
        logo: 'logo-19.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/tables?partner=netent',
        logo: 'logo-20.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    // {
    //     link: '/games/slots?partner=pgsoft',
    //     logo: 'logo-21.png',
    //     alt: 'partner',
    //     require_auth: false,
    //     key: false,
    // },
    {
        link: '/games/slots?partner=redtiger',
        logo: 'logo-22.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    // Saba
    {
        link: '/saba-sports',
        logo: 'logo-23.png',
        alt: 'partner',
        require_auth: false,
        key: 'saba-sports',
        api_url: '/athena/sportUrl',
    },
    {
        link: '/games/instants?partner=spribe',
        logo: 'logo-24.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
    {
        link: '/games/lottery?partner=askmelotto',
        logo: 'logo-25.png',
        alt: 'partner',
        require_auth: false,
        key: false,
    },
]
const { isMobile } = useDevice()
const localePath = useLocalePath()
const PARTNER_LINK = useRuntimeConfig().public.PARTNER_LINK
const handleProviderClick = async (partner) => {
    if (PARTNER_LINK === '0') return
    if (partner.require_auth && !isLoggedIn.value) {
        showLoginModal.value = true
        return
    }
    if (partner.key === false) {
        navigateTo(partner.link)
    }
    if (partner.key === 'ksports') {
        await handleSpecial(partner)
    }
    if (partner.key === 'btisports') {
        await handleSpecial(partner)
    }
    if (partner.key === 'saba-sports') {
        await handleSpecial(partner)
    }
    if (partner.key === 'im-sports') {
        await handleSpecial(partner)
    }
    if (partner.key === 'betradar') {
        await handleSpecial(partner)
    }
    if (partner.key === 'digmaan') {
        // if mobile get url from api, open new tab
        const newtab = window.open('', '_blank')
        try {
            // to be refactored
            const { data } = await useFetch(partner.api_url, {
                headers,
                baseURL: '/api/v1',
            })
            if (data.value.status === 'OK') {
                const { url } = data.value.data
                if (newtab) newtab.location.href = url
            } else {
                if (newtab) newtab.close()
                useAlertStoreInstance.showMessageError({
                    value: {
                        statusCode: 'SHOW_MESSAGE',
                        message: t('error.server_is_busy'),
                    },
                })
            }
        } catch (error) {
            console.log(error)

            if (newtab) newtab?.close()
        }
    }
}
const headers = useRequestHeaders(['cookie'])
const handleSpecial = async (item = {}) => {
    // if desktop rout to page
    if (!isMobile) {
        navigateTo(localePath(item.link))
        return
    }
    // if mobile get url from api, open new tab
    try {
        const newtab = window.open('', '_blank')
        // to be refactored
        const { data } = await useFetch(item.api_url, {
            headers,
            baseURL: '/api/v1',
        })
        if (data.value.status === 'OK') {
            const { url } = data.value.data
            if (newtab) newtab.location.href = url
        } else {
            if (newtab) newtab.close()
            useAlertStoreInstance.showMessageError({
                value: {
                    statusCode: 'SHOW_MESSAGE',
                    message: t('error.server_is_busy'),
                },
            })
        }
    } catch (error) {
        if (newtab) newtab.close()
        console.error(error)
    }
}
</script>
<style lang="scss" scoped></style>
