<template>
    <div
        class="rounded-md border border-[#EAEBEB] bg-white p-[10px] font-semibold blur-sm md:mb-2 lg:shadow-none"
    >
        <div class="flex items-center justify-between">
            <div
                class="w-[50%] truncate text-[11px] font-medium text-[#737373] lg:w-[70%]"
            >
                event
            </div>
            <div class="line-clamp-1 text-[11px] font-medium text-[#737373]">
                date/time
            </div>
        </div>
        <div class="flex items-center justify-between">
            <img
                src="/assets/img/hot-match/fc_sample.jpg"
                alt="fc_sample.jpg"
                class="h-[30px] w-[30px] rounded-full border bg-white object-contain p-1"
                onerror='this.onerror = null; this.src="/assets/img/hot-match/fc_sample.jpg";'
                loading="lazy"
            />

            <div
                class="my-1 flex w-[60%] flex-col items-center justify-center text-[12px] font-semibold lg:my-4"
            >
                <div class="w-[100%] truncate text-center">team</div>
                <div class="w-[100%] truncate text-center">team</div>
            </div>
            <img
                src="/assets/img/hot-match/fc_sample.jpg"
                alt="fc_sample.jpg"
                class="h-[30px] w-[30px] rounded-full border bg-white object-contain p-1"
                onerror='this.onerror = null; this.src="/assets/img/hot-match/fc_sample.jpg";'
                loading="lazy"
            />
        </div>

        <div class="grid grid-cols-2 gap-x-[8px] gap-y-[4px]">
            <div
                class="font-semibol flex min-h-[25px] items-center justify-around rounded-md bg-[#F7F7F7] pr-1 text-[12px]"
            >
                <p class="w-[15%] text-center text-[#848484]">H</p>
                <p
                    class="odds min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
                <p
                    class="odds odds-away min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
            </div>

            <div
                class="font-semibol flex min-h-[25px] items-center justify-around rounded-md bg-[#F7F7F7] pr-1 text-[12px]"
            >
                <p class="w-[15%] text-center text-[#848484]">O</p>
                <p
                    class="odds min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
                <p
                    class="odds odds-away min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
            </div>

            <div
                class="font-semibol flex min-h-[25px] items-center justify-around rounded-md bg-[#F7F7F7] pr-1 text-[12px]"
            >
                <p class="w-[15%] text-center text-[#848484]">A</p>
                <p
                    class="odds min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
                <p
                    class="odds odds-away min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
            </div>

            <div
                class="font-semibol flex min-h-[25px] items-center justify-around rounded-md bg-[#F7F7F7] pr-1 text-[12px]"
            >
                <p class="w-[15%] text-center text-[#848484]">U</p>
                <p
                    class="odds min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
                <p
                    class="odds odds-away min-w-[25%] text-right tracking-[-1.2px] xs:tracking-[0.5px]"
                >
                    0
                </p>
            </div>
        </div>
    </div>
</template>
