<template>
    <div class="home-page">
        <div class="hidden lg:block">
            <ThaBlocksHomeSwiperV3 />
        </div>
        <!-- 4 Cards desktop -->
        <!-- <div class="container my-10 hidden lg:block lg:min-h-[70px]">
            <BlocksMainCards />
        </div> -->
        <div class="container hidden lg:block">
            <ThaBlocksCategoryGame />
        </div>
        <div
            class="container mb-4 min-h-[36.279vw] max-sm:pr-0 xs:min-h-[120px] lg:my-10 lg:hidden lg:px-4"
        >
            <BlocksLiveCasinosV3 />
        </div>

        <div class="min-h-[130px] lg:min-h-[240px]">
            <BlocksFootballHotMatchBti
                v-if="useRuntimeConfig().public.EVENT_EURO"
            />
            <BlocksFootballHotMatch v-else />
        </div>
        <div
            class="container mb-4 hidden min-h-[36.279vw] max-sm:pr-0 xs:min-h-[120px] lg:my-10 lg:flex lg:px-4"
        >
            <BlocksLiveCasinosV3 />
        </div>
        <div class="container my-0 h-full lg:pb-0">
            <!-- 4 Cards desktop -->

            <ThaBlocksHotGame />
        </div>
        <div class="ml-2 rounded-l-lg bg-grey-100 py-4 pl-4 lg:ml-0 lg:p-0">
            <div class="container pl-0 max-sm:pr-0">
                <ThaBlocksPartnersV2 />
            </div>
        </div>
    </div>
</template>

<script setup>
// import { useJackpotStore } from '~/stores'

// const useJackpotStoreInstance = useJackpotStore()

// onBeforeUnmount(() => {
//     useJackpotStoreInstance.clearGetJackpots()
// })
onMounted(async () => {
    // useJackpotStoreInstance.startGetJackpots()
})
</script>

<style lang="scss" scoped></style>
