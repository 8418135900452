<template>
    <div class="pt-4 lg:pt-0">
        <div class="mb-2 justify-between lg:mb-5 lg:flex">
            <div class="text-sm font-bold lg:text-lg">
                {{ $t('header.nav_menu.live_casino') }}
            </div>
            <ThaCommonViewMore
                :link="`/casino/all?partner=`"
                class="hidden lg:flex"
            />
        </div>
        <div
            class="no-scrollbar mx-auto flex w-full gap-2 overflow-x-scroll md:grid md:grid-cols-5 lg:overflow-visible"
        >
            <div
                v-for="(item, index) in listCasino"
                :key="item.id"
                class="loader-image relative max-w-full flex-none duration-200 hover:cursor-pointer lg:hover:scale-105"
                @click="$router.push(item.link)"
            >
                <img
                    :src="item.img"
                    :alt="`live-casino-${index}`"
                    class="mx-auto hidden md:flex"
                    loading="eager"
                />
                <img
                    :src="item.img_mb"
                    :alt="`live-casino-${index}-mb`"
                    class="mx-auto max-w-[125px] md:hidden"
                    loading="eager"
                />
            </div>
        </div>
    </div>
</template>
<script setup>
const staticUrl = useRuntimeConfig().public.staticUrl
const listCasino = ref([
    {
        id: 1,
        link: '/casino/baccarat?partner=&filter=none',
        title: 'baccarat',
        img: `${staticUrl}/assets/img/live-casinos-v3/baccarat.png`,
        img_mb: `${staticUrl}/assets/img/live-casinos-v3/baccarat_mb.png`,
    },
    {
        id: 2,
        link: '/casino/blackjack?partner=&filter=none',
        title: 'blackjack',
        img: `${staticUrl}/assets/img/live-casinos-v3/blackjack.png`,
        img_mb: `${staticUrl}/assets/img/live-casinos-v3/blackjack_mb.png`,
    },
    {
        id: 3,
        link: '/casino/sicbo?partner=&filter=none',
        title: 'sicbo',
        img: `${staticUrl}/assets/img/live-casinos-v3/sicbo.png`,
        img_mb: `${staticUrl}/assets/img/live-casinos-v3/sicbo_mb.png`,
    },
    {
        id: 4,
        link: '/casino/roulette?partner=&filter=none',
        title: 'roulette',
        img: `${staticUrl}/assets/img/live-casinos-v3/roulette.png`,
        img_mb: `${staticUrl}/assets/img/live-casinos-v3/roulette_mb.png`,
    },
    {
        id: 5,
        link: '/casino/dragontiger?partner=&filter=none',
        title: 'dragontiger',
        img: `${staticUrl}/assets/img/live-casinos-v3/dragontiger.png`,
        img_mb: `${staticUrl}/assets/img/live-casinos-v3/dragontiger_mb.png`,
    },
])
</script>
<style lang="scss" scoped></style>
