<template>
    <div
        class="relative h-auto overflow-hidden rounded-md duration-200 hover:scale-105"
    >
        <NuxtLink :to="game?.link">
            <img
                :src="`${staticUrl}/assets/img/home/category-game/${game?.thumb}`"
                class="aspect-auto-[465/300] w-full"
                :alt="game?.title"
                loading="lazy"
            />
        </NuxtLink>
    </div>
</template>
<script setup>
const staticUrl = useRuntimeConfig().public.staticUrl
defineProps({
    game: {
        type: Object,
        default: () => ({}),
    },
})
</script>
<style lang="scss" scoped></style>
